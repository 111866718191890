
.box {
  width: 75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.5rem;
  z-index: 99;
  padding-bottom: 1rem;

  @media (max-width: 1400px) {
    width: 90%;
  }
}

.leftSide {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Rubik";
}

.rightSide {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Rubik";

  @media (max-width: 800px) {
    display: none;
  }
}

.menuBoxWithBorder {
  border-right: 2px solid #000000;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 1rem;
  margin-left: 1rem;

  p {
    font-size: 1rem;
    font-weight: bold;
    color: #171f46;
    text-transform: uppercase;
    cursor: pointer;

    @media (max-width: 1200px) {
      font-size: 0.7rem;
    }
  }
}

.dropDownMenuIcon {
  display: none;

  @media (max-width: 800px) {
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.menuIcon {
  width: 2rem;
  height: 2rem;
}

.menuBox {
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 1rem;
  margin-left: 1rem;

  p {
    font-size: 1rem;
    font-weight: bold;
    color: #171f46;
    text-transform: uppercase;
    cursor: pointer;

    @media (max-width: 1200px) {
      font-size: 0.7rem;
    }
  }
}

.dropDownMenuItem {
  height: 2rem;
  width: 90%;
  margin-top: 1rem;

  p {
    font-size: 1rem;
    font-weight: bold;
    color: #171f46;
    text-transform: uppercase;
    cursor: pointer;
  }
}

.menuButtonBox {
  margin-left: 1rem;
}

.button {
  background-color: #1a1718;

  @media (max-width: 1200px) {
    font-size: 0.7rem !important;
    border: none !important;
  }
}

.menuDropdownButton {
  background-color: #1a1718;
  width: 100% !important;

  p {
    color: white;
  }
}

.dropDownMenuBox {
  position: absolute;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  top: 6rem;
  z-index: 99;
  height: 18rem;
}
