.belowContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5rem;
  
    @media (max-width: 1400px) {
      margin-left: 6rem;
    }
  
    @media (max-width: 1250px) {
      margin-left: 5rem;
    }
  
    @media (max-width: 1100px) {
      margin-left: 4rem;
      margin-top: 5rem;
    }
  
    @media (max-width: 900px) {
      margin-left: 0rem;
    }
  }

  .headingBelowContent {
    font-size: 2.6rem;
    color: black;
    font-weight: bold;
  
    @media (max-width: 600px) {
      font-size: 1.8rem;
    }
  }

  .headingBelowContentImage {
    width: 20rem;
    height: 15rem;
    margin-top: -8rem;
  
    @media (max-width: 600px) {
      width: 15rem;
      height: 10rem;
      margin-top: -6rem;
    }
  }

  .facilitySubText {
    font-size: 1rem;
    color: #7e858e;
    line-height: 2rem;
    margin-top: 1rem;
    text-align: left;
    width: 80%;
  
    @media (max-width: 600px) {
      width: 100%;
    }
  }

  .width_80{
    width: 80%;
  
    @media (max-width: 600px) {
      width: 100%;
    }
  }