.InputBox {
  width: 100%;
}

.label {
  font-size: 0.9rem;
  font-weight: bold;
  color: #7e858e;
}

.input {
  width: 95%;
  border: 1px solid #7e858e;
  margin-top: 0.5rem;
  font-size: 0.9rem;
  height: 2.5rem;
  padding-left: 1rem;
}
