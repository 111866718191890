.box {
  width: 100%;
  background-color: black;

  @media (max-width: 1200px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.container {
  display: flex;
  justify-content: space-around;
  margin: 6rem;
  align-items: flex-start;

  @media (max-width: 1400px) {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  @media (max-width: 1200px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin-left: 0rem;
    margin-right: 0rem;
    margin-top: 0rem;
  }
}

.leftSide {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Rubik";

  @media (max-width: 600px) {
    margin-top: 5rem;
  }
}

.leftSideBottom {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Rubik";
  flex-direction: column;

  @media (max-width: 600px) {
    margin-top: 5rem;
  }
}

.menuBox {
  width: 10rem;

  margin-right: 1rem;
  margin-left: 1rem;

  p {
    color: white;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  span {
    color: white;
    font-size: 0.8rem;
    font-weight: bold;
    display: block;
    margin-top: 1rem;
  }

  @media (max-width: 1200px) {
    margin-top: 5rem;
  }

  @media (max-width: 1050px) {
    text-align: center;
  }
}

.menuBoxItem {
  color: white;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
}

.menuBoxItemSpace {
  color: white;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 3rem;
  cursor: pointer;
}

.button {
  background-color: white;
  margin-top: 2rem;
  p {
    color: black;
  }

  @media (max-width: 1200px) {
    font-size: 0.7rem !important;
  }
}

.menuItemsBox {
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 1200px) {
    margin-top: 5rem;
    align-items: flex-start;
    text-align: left;
  }

  @media (max-width: 1050px) {
    align-items: center;
    text-align: center;
  }
}

.leftSideContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 18rem;

  @media (max-width: 1200px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 10rem;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
  }
}

.socialsFirst {
  width: 2rem;
  height: 2rem;
}

.socials {
  width: 2rem;
  height: 2rem;
  margin-left: 1rem;
}

.socialContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.menuBoxContainer {
  display: flex;

  @media (max-width: 1050px) {
    flex-direction: column;
  }
}
