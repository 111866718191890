.Box {
  width: 100%;
  max-width: 65rem;
  padding-top: 5rem;
  padding-bottom: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1000px) {
    max-width: 100%;
  }
}

.container {
  width: 40rem;

  @media (max-width: 1300px) {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 5rem;
  }

  @media (max-width: 1000px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 0rem;
  }
}

.headingBox {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 33rem;

  p {
    color: black;
    font-size: 2.5rem;
    font-weight: bold;
    margin-top: 1rem;
    z-index: 2;

    @media (max-width: 1300px) {
      font-size: 2.5rem;
    }

    @media (max-width: 1000px) {
      font-size: 1.8rem;
      width: 90%;

      text-align: center;
    }

    @media (max-width: 700px) {
      font-size: 2rem;
    }

    @media (max-width: 600px) {
      font-size: 1.8rem;
      text-align: center;
    }

    @media (max-width: 400px) {
      font-size: 1.3rem;
    }
  }

  @media (max-width: 600px) {
    width: 90%;
  }
}

.highlighter {
  width: 25rem;
  margin-top: -6rem;
  margin-left: -4rem;

  @media (max-width: 1000px) {
    width: 15rem;
    margin-top: -4rem;
  }
}

.subHeading {
  width: 33rem;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 2.2rem;
  margin-top: 1rem;

  @media (max-width: 1000px) {
    text-align: center;
    width: 28rem;
  }

  @media (max-width: 600px) {
    width: 90%;
    font-size: 1rem;
    line-height: 1.8rem;
  }
}

.input {
  border: none;
  width: 30rem;
  border-bottom: 1px solid #1a1718;
  padding: 1rem;
  font-size: 1.2rem;
  margin-top: 3rem;

  @media (max-width: 600px) {
    width: 80%;
  }
}

.button {
  width: 15rem;
  background-color: #1a1718;
  margin-top: 4rem;

  p {
    color: white;
    font-size: 0.8rem;
  }

  @media (max-width: 400px) {
    width: 90%;
  }
}
