body {
  overflow-x: hidden;
}

.Box {
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.firstContentBox {
  width: 100%;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(
    359.65deg,
    #efe7ff 0.35%,
    #fbfbfb 99.75%,
    #fbfbfb 99.75%
  );
}

.firstContentImageBox {
  width: 60%;
  height: 40rem;
  margin-bottom: 5rem;

  @media (max-width: 800px) {
    height: 20rem;
    margin-top: 2rem;
    width: 90%;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
}

.highLighterOneBox {
  margin-top: 9rem;
  margin-left: 5rem;

  @media (max-width: 1400px) {
    margin-left: 6rem;
  }

  @media (max-width: 1250px) {
    margin-top: 6.5rem;
    margin-left: 5rem;
  }

  @media (max-width: 1100px) {
    margin-left: 4rem;
    margin-top: 5rem;
  }

  @media (max-width: 800px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 0rem;
  }

  @media (max-width: 600px) {
    margin-top: 4rem;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    padding-left: 0.5rem;
  }

  @media (max-width: 550px) {
    margin-top: 6rem;
  }
}

.highLighterHeadingOne {
  font-size: 1.8rem;
  color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  margin-bottom: 10rem;

  p {
    z-index: 5;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
  }

  @media (max-width: 800px) {
    font-size: 1.2rem;
    margin-bottom: 5rem;
    text-align: center;
    width: 90%;
  }
}

.mainHeadingOne {
  font-size: 2.5rem;
  color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  font-weight: bold;
  justify-content: center;

  p {
    z-index: 5;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
  }

  @media (max-width: 800px) {
    font-size: 1.4rem;
  }
}

.highlighter1 {
  margin-left: -12rem;
  margin-top: 1rem;

  @media (max-width: 550px) {
    opacity: 0;
  }
}

.headingTwo {
  p {
    font-size: 4rem;
    color: white;
    font-weight: bold;
    margin: 0;
    padding: 0;
    margin-top: -1rem;

    @media (max-width: 1400px) {
      font-size: 3rem;
    }

    @media (max-width: 1100px) {
      font-size: 2.2rem;
    }

    @media (max-width: 800px) {
      font-size: 1.5rem;
    }
  }
}

.stateInputBox {
  width: 100%;
  padding: 1.5rem;
  border-radius: 4.5rem;
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: space-between;

  input {
    width: 80%;
    border: none;
    font-size: 1rem;
  }

  @media (max-width: 600px) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
}

.inputButtonBox {
  width: 15%;

  @media (max-width: 600px) {
    margin-top: 2rem;
    width: 40%;
  }
}

.headingThree {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60rem;
  margin-top: 3rem;

  @media (max-width: 1300px) {
    width: 90%;
  }
}

.button {
  width: 100%;
  background-color: #1a1718
}

.belowContent {
  width: 100%;
  margin-bottom: 5rem;
  margin-top: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 900px) {
    margin-top: 0rem;
  }
}

.headingBelowContent {
  font-size: 2.6rem;
  color: black;
  font-weight: bold;

  @media (max-width: 600px) {
    font-size: 1.8rem;
  }
}

.headingBelowContentImage {
  width: 20rem;
  height: 15rem;
  margin-top: -8rem;

  @media (max-width: 600px) {
    width: 15rem;
    height: 10rem;
    margin-top: -6rem;
  }
}

.belowContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1400px) {
    margin-left: 6rem;
  }

  @media (max-width: 1250px) {
    margin-left: 5rem;
  }

  @media (max-width: 1100px) {
    margin-left: 4rem;
    margin-top: 5rem;
  }

  @media (max-width: 900px) {
    margin-left: 0rem;
  }
}

.belowContentContainer {
  display: flex;
  width: 90%;
  justify-content: center;
  align-items: center;

  @media (max-width: 1400px) {
    margin-left: 6rem;
    width: 80%;
  }

  @media (max-width: 1250px) {
    margin-left: 5rem;
  }

  @media (max-width: 1100px) {
    margin-left: 4rem;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 0rem;
    width: 90%;
  }
}

.contentsText {
  width: 35rem;

  @media (max-width: 700px) {
    width: 100%;
    margin-left: 0rem;
  }

  p {
    font-size: 1.1rem;
    line-height: 32px;
  }
}

.contentsTextSecond {
  width: 30rem;
  margin-left: 10rem;

  @media (max-width: 900px) {
    margin-left: 1rem;
    margin-top: 1rem;
  }

  @media (max-width: 700px) {
    width: 100%;
    margin-left: 0rem;
  }

  p {
    font-size: 1.1rem;
    line-height: 32px;
  }
}

.facilityIcon {
  width: 5rem;
  height: 5rem;
}

.boxTwo {
  width: 65rem;
  margin-top: 5rem;
  margin-bottom: 5rem;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1200px) {
    width: 90%;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    margin-top: 5rem;
    justify-content: center;
  }
}
