.Box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: white;
}

.topHeading {
  color: #6b38fb;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 500;

  @media (max-width: 550px) {
    font-size: 1rem;
    text-align: center;
    width: 90%;
  }
}

.heading {
  color: black;
  font-size: 3.5rem;
  font-weight: bold;
  margin-top: 1rem;

  @media (max-width: 550px) {
    font-size: 2rem;
  }
}

.newheading {
  color: black;
  font-size: 3rem;
  font-weight: bold;
  margin-top: 1rem;

  @media (max-width: 1300px) {
    font-size: 2.5rem;
  }

  @media (max-width: 1100px) {
    text-align: center;
  }

  @media (max-width: 550px) {
    font-size: 2rem;
  }
}

.ContentBox {
  display: flex;
  justify-content: center;
  margin-top: 5rem;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;

    margin-top: 3rem;
  }
}

.image {
  height: 100%;
  object-fit: contain;

  @media (max-width: 1300px) {
    width: 30rem;
  }

  @media (max-width: 550px) {
    width: 100%;
  }
}

.leftContentBox {
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @media (max-width: 1000px) {
    margin-top: 3rem;
  }

  @media (max-width: 550px) {
    margin-left: 0rem;
    align-items: center;
    justify-content: center;
  }
}

.leftContentContainer {
  display: flex;

  @media (max-width: 1000px) {
    margin-top: 2rem;
  }

  @media (max-width: 550px) {
    width: 90%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
  }
}

.iconHeading {
  font-size: 1.2rem;
  color: #6b38fb;
  font-weight: 600;
  text-transform: uppercase;

  @media (max-width: 1300px) {
    font-size: 1rem;
  }

  @media (max-width: 550px) {
    text-align: center;
  }
}

.iconContent {
  font-size: 1rem;
  line-height: 2rem;
  margin-top: 0.625rem;
  color: #7e858e;

  @media (max-width: 1300px) {
    font-size: 0.8rem;
    margin-top: 0.325rem;
  }

  @media (max-width: 550px) {
    text-align: center;
  }
}

.iconImage {
  width: 5rem;
  height: 5rem;

  @media (max-width: 1300px) {
    width: 4rem;
    height: 4rem;
  }
}

.iconContainer {
  margin-left: 0.625rem;
  width: 20rem;

  @media (max-width: 1300px) {
    width: 15rem;
  }

  @media (max-width: 1000px) {
    width: 20rem;
  }

  @media (max-width: 550px) {
    width: auto;
    margin-top: 1rem;
  }
}

.secondContentNewHeadingBox {
  width: 80%;

  @media (max-width: 600px) {
    width: 90%;
  }
}
