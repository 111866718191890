.Box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: white;
}

.heading {
  color: black;
  font-size: 3rem;
  font-weight: bold;
  margin-top: 1rem;
  z-index: 2;
  text-align: center;
  width: 50rem;

  @media (max-width: 1300px) {
    font-size: 2.5rem;
  }

  @media (max-width: 1000px) {
    font-size: 1.8rem;
    width: 90%;
  }

  @media (max-width: 700px) {
    font-size: 2rem;
    text-align: center;
  }

  @media (max-width: 600px) {
    font-size: 1.8rem;
    text-align: center;
  }

  @media (max-width: 400px) {
    font-size: 1.3rem;
  }
}

.rightContentHeading {
  color: black;
  font-size: 2.5rem;
  font-weight: 600;
  margin-top: 1rem;
  z-index: 2;
  text-align: center;
  margin-top: 2rem;
  line-height: 3rem;
  letter-spacing: 0.5rem;

  @media (max-width: 1300px) {
    font-size: 2.5rem;
  }

  @media (max-width: 1000px) {
    font-size: 1.8rem;
  }

  @media (max-width: 700px) {
    font-size: 2rem;
    text-align: center;
  }
}

.subHeading {
  color: #7e858e;
  font-size: 1.5rem;
  margin-top: 2rem;
  text-transform: uppercase;

  span {
    border-bottom: 5px solid #6b38fb;
  }

  @media (max-width: 1050px) {
    line-height: 1.8rem;
    text-align: center;
    width: 90%;
  }

  @media (max-width: 600px) {
    font-size: 1.3rem;
  }

  @media (max-width: 400px) {
    font-size: 1rem;
  }
}

.belowContent {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  max-width: 65rem;

  @media (max-width: 1100px) {
    max-width: 55rem;
  }

  @media (max-width: 800px) {
    flex-direction: column;
  }
}

.leftContent {
  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.iconContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 2rem;
}

.iconHeading {
  font-size: 1.5rem;
  color: black;
  font-weight: bold;
}

.iconImage {
  margin-top: -1rem;
  width: 10rem;
  height: 10rem;

  @media (max-width: 1050px) {
    width: 7rem;
    height: 7rem;
  }
}

.iconText {
  color: #7e858e;
  font-size: 1rem;
  line-height: 2.5rem;
  width: 15rem;

  @media (max-width: 1050px) {
    line-height: 1.8rem;
  }

  @media (max-width: 600px) {
    width: 12rem;
  }
}

.rightContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;

  @media (max-width: 800px) {
    margin-top: 4rem;
  }
}

.image1 {
  width: 20rem;

  @media (max-width: 1050px) {
    width: 15rem;
  }
}

.image2 {
  width: 15rem;
  margin-left: 1rem;

  @media (max-width: 1050px) {
    width: 10rem;
  }
}

.imageContainer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 1rem;
}

.button {
  width: 15rem;
  background-color: #1a1718;
  margin-top: 2rem;

  p {
    color: white;
    font-size: 0.8rem;
  }

  @media (max-width: 400px) {
    width: 90%;
  }
}
