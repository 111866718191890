.Box {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 5rem;
  padding-bottom: 5rem;
  padding-left: 2rem;

  @media (max-width: 1100px) {
    flex-direction: column;
  }
}

.leftBox {
  display: flex;
  justify-content: flex-start;
  margin-top: 5rem;

  @media (max-width: 1300px) {
    margin-top: 3rem;
  }

  @media (max-width: 700px) {
    flex-direction: column;
  }
}

.headingBox {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media (max-width: 600px) {
    align-items: center;
    justify-content: center;
  }
}

.headingTextBox {
  display: flex;
  align-items: center;

  p {
    font-size: 2.2rem;
    font-weight: bold;
    color: black;
    z-index: 1;

    @media (max-width: 1300px) {
      font-size: 1.8rem;
    }
  }

  span {
    font-size: 0.8rem;
    color: black;
    font-weight: bold;
    margin-top: 0.3125rem;
    margin-left: 0.625rem;

    @media (max-width: 1300px) {
      font-size: 0.7rem;
    }
  }
}

.highlighter {
  margin-left: -6.5rem;
  margin-top: 3rem;
}

.highlighterSpecial {
  margin-left: -5.5rem;
  margin-top: 3rem;
}

.subTextBox {
  width: 14rem;
  margin-top: 1rem;

  p {
    font-size: 1rem;
    color: #7e858e;
    line-height: 2rem;

    @media (max-width: 1300px) {
      font-size: 0.8rem;
    }
  }
}

.secondBox {
  margin-top: 2rem;
}

.secondBoxSpecial {
  margin-top: 4rem;
}

.secondColumn {
  margin-left: 3rem;

  @media (max-width: 1100px) {
    margin-left: 5rem;
  }

  @media (max-width: 700px) {
    margin-left: 0rem;
    margin-top: 2rem;
  }
}

.heading {
  color: black;
  font-size: 3rem;
  font-weight: bold;
  margin-top: 1rem;

  @media (max-width: 1300px) {
    font-size: 2.5rem;
  }

  @media (max-width: 1100px) {
    text-align: center;
  }

  @media (max-width: 550px) {
    font-size: 2rem;
  }
}

.headingTwo {
  color: black;
  font-size: 3rem;
  font-weight: bold;
  margin-top: 3rem;

  span {
    color: #6b38fb;
  }

  @media (max-width: 1300px) {
    font-size: 2.5rem;
  }

  @media (max-width: 1100px) {
    text-align: center;
  }

  @media (max-width: 550px) {
    font-size: 2rem;
  }
}

.image {
  object-fit: contain;
  width: 60%;

  @media (max-width: 1300px) {
    margin-top: 3rem;
    width: 40%;
  }

  @media (max-width: 1000px) {
    margin-top: 3rem;
    width: 100%;
  }
}

// second content

.boxTwo {
  width: 100%;
  margin-top: 10rem;
  margin-bottom: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 900px) {
    flex-direction: column;
    margin-top: 5rem;
  }
}

.facilityBox {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.facilityBoxSpace {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 2rem;

  @media (max-width: 900px) {
    margin-top: 5rem;
  }
  @media (max-width: 600px) {
    margin-left: 0rem;
  }
}

.facilityIcon {
  z-index: 1;

  @media (max-width: 1000px) {
    width: 4rem;
    height: 4rem;
  }
}

.facilityIconBackground {
  margin-top: -9rem;

  @media (max-width: 1000px) {
    width: 10rem;
    height: 10rem;
    margin-top: -7rem;
  }
}

.facilityHeading {
  font-size: 1.2rem;
  color: black;
  font-weight: bold;
  text-align: center;
  margin-top: 1rem;
  line-height: 2rem;

  @media (max-width: 1000px) {
    font-size: 1rem;
  }
}

.facilitySubText {
  font-size: 1rem;
  color: #7e858e;
  line-height: 2rem;
  width: 20rem;
  margin-top: 1rem;
  text-align: center;

  @media (max-width: 1300px) {
    font-size: 0.9rem;
    width: 15rem;
  }

  @media (max-width: 1000px) {
    font-size: 0.8rem;
    width: 13rem;
  }

  @media (max-width: 400px) {
    width: 90%;
  }
}

.button {
  width: 15rem;
  background-color: white;
  margin-top: 1rem;

  p {
    color: #171f46;
  }

  @media (max-width: 400px) {
    width: 90%;
  }
}

.thirdContentHeading {
  p {
    color: black;
    font-size: 3rem;
    font-weight: bold;
    margin-top: 1rem;
    z-index: 2;

    @media (max-width: 1300px) {
      font-size: 2.5rem;
    }

    @media (max-width: 1000px) {
      font-size: 1.8rem;
    }

    @media (max-width: 700px) {
      font-size: 2rem;
      text-align: center;
    }
  }

  @media (max-width: 700px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.thirdContenHighlighter {
  margin-left: -1.5rem;
  margin-bottom: -7.5rem;
  z-index: 1;

  @media (max-width: 1300px) {
    width: 15rem;
    margin-bottom: -4.5rem;
  }

  @media (max-width: 700px) {
    margin-left: 0rem;
  }

  // @media (max-width: 550px) {
  //   margin-left: 6rem;
  // }

  // @media (max-width: 450px) {
  //   margin-left: 4rem;
  // }

  // @media (max-width: 400px) {
  //   margin-left: 2rem;
  // }
}

.thirdContentSubText {
  font-size: 1.3rem;
  line-height: 2rem;
  color: #7e858e;
  width: 20rem;
  margin-top: 1rem;

  @media (max-width: 600px) {
    width: 90%;
    text-align: center;
  }
}

.thirdContentBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  padding-top: 5rem;
  max-width: 65rem;

  @media (max-width: 1300px) {
    max-width: 48rem;
  }

  @media (max-width: 1100px) {
    max-width: 45rem;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
}

.thirdContentBoxTwo {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 5rem;
  margin-top: -5rem;
  max-width: 65rem;

  @media (max-width: 1300px) {
    max-width: 48rem;
  }

  @media (max-width: 1100px) {
    max-width: 45rem;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
}

.thirdContentImage {
  height: 100%;
  object-fit: contain;

  @media (max-width: 1300px) {
    width: 20rem;
  }

  @media (max-width: 1100px) {
    margin-top: 3rem;
    width: 22rem;
  }

  @media (max-width: 700px) {
    width: 100%;
  }
}

.thirdContentImageTwo {
  height: 100%;
  object-fit: contain;
  margin-bottom: -5rem;

  @media (max-width: 1300px) {
    width: 22rem;
  }

  @media (max-width: 1100px) {
    margin-top: 3rem;
    width: 22rem;
  }

  @media (max-width: 700px) {
    width: 100%;
  }
}

.thirdContentImageTwoSpecial {
  height: 100%;
  object-fit: contain;
  margin-bottom: -8rem;

  @media (max-width: 1300px) {
    width: 22rem;
  }

  @media (max-width: 1100px) {
    margin-top: 3rem;
    width: 22rem;
  }

  @media (max-width: 700px) {
    width: 100%;
  }
}

.thirdContentImageThree {
  height: 100%;
  object-fit: contain;
  margin-bottom: -5rem;

  @media (max-width: 1300px) {
    width: 22rem;
  }

  @media (max-width: 1100px) {
    margin-top: 3rem;
    width: 22rem;
  }

  @media (max-width: 700px) {
    width: 90%;
  }
}
