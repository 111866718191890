p {
  font-family: var("Rubik");
  margin: 0;
  padding: 0;
}

span {
  font-family: var("Rubik");
  margin: 0;
  padding: 0;
}

input:focus {
  outline: none;
}

.sq-payment-form {
  margin-top: 1rem !important;
  width: 85% !important;

  @media (max-width: 1200px) {
    width: 95% !important;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;